import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/Seo"
import { Col, Row, Container, CardDeck, Card } from "react-bootstrap"
import ContactData from "../components/contactdata"
import TopicList from "../components/topicList"
import CheckText from "../components/textwithcheck"
import FaQ from "../components/FaQ"
import Aos from "aos"
import ZapisConsult from "../components/zapisConsultation"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PortableText from "react-portable-text"

const IndexPage = ({ data }) => {
  useEffect(() => {
    Aos.init({ duration: 1000, once: true })
  }, [])
  const {
    header,
    header2,
    thumbnail,
    title,
    _rawBody,
    _rawPrinciplesBlock,
    _rawPricingBlock,
    workExperience,
  } = data.sanityHome
  const picture = getImage(thumbnail.asset)
  const serializer = {
    strong: props => <strong className="text-white" {...props} />,
  }
  return (
    <Layout>
      <Seo
        title={title}
        keywords={[
          "психолог",
          "барселона",
          "степаненко",
          "александр",
          "зависимость",
          "алкоголизм",
          "наркомания",
          "помощь",
        ]}
      />
      <Container fluid="xl">
        <Row>
          <Col
            xl={8}process
            lg={8}
            md={12}
            sm={12}
            xs={12}
            className="py-5 arrowbackground"
          >
            <Container fluid="lg">
              <Row className="uk-heading-divider">
                <Col xl={4} lg={4} md={3} sm={2} xs={12} className="pb-3">
                  <figure>
                    <GatsbyImage
                      image={picture}
                      data-aos="fade-in"
                      className="rounded"
                      alt={thumbnail.attribution || "Степаненко Александр"}
                    />
                    {thumbnail.caption !== null && (
                      <figcaption className="text-white pt-2">
                        {thumbnail.caption}
                      </figcaption>
                    )}
                  </figure>
                </Col>
                <Col xl={8} lg={8} md={9} sm={10} xs={12}>
                  <div className="text-white" data-aos="fade-in">
                    <h1 className="display-6 uk-heading-divider">
                      {header}
                      <br />
                      <span style={{ fontWeight: "200" }}>{header2}</span>
                    </h1>
                    <div>
                      <PortableText
                        className="uk-text-meta"
                        content={_rawBody}
                        serializers={serializer}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2 className="display-4 pt-2" data-aos="fade-in">
                    {_rawPrinciplesBlock.principleMainHeader}
                  </h2>
                  {_rawPrinciplesBlock.principleWords.map(
                    (principleWord, _key) => (
                      <p
                        key={_key}
                        style={{ textTransform: "uppercase" }}
                        className="uk-heading-bullet text-light display-6"
                        data-aos="fade-right"
                      >
                        {principleWord}
                      </p>
                    )
                  )}
                  <h3 className="display-4 pt-2" data-aos="fade-in">
                    {_rawPricingBlock.pricingMainHeader}
                  </h3>
                  <CardDeck>
                    {_rawPricingBlock.prices.map((price, _key) => (
                      <Card
                        key={_key}
                        className="uk-card-default mb-3"
                        data-aos="zoom-in"
                        style={{ minWidth: "18rem" }}
                     >
                        <Card.Body>
                          <Card.Title className="text-light">
                            {price.service}
                          </Card.Title>
                          <Card.Text as="div">
                            <p className="display-4 pt-2">{price.price}</p>
                          </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                          <p className="text-light">{price.duration}</p>
                        </Card.Footer>
                      </Card>
                    ))}
                  </CardDeck>
                </Col>
              </Row>
            </Container>
          </Col>
          {/* /* basic topics */}
          <Col xl={4} lg={4} md={12} sm={12} xs={12} className="p-5 bg-primary">
            <TopicList />
            <FaQ />
          </Col>
        </Row>
      </Container>
      <div style={{ width: "100%" }} className="bg-secondary">
        <Container fluid="xl">
          <Row>
            <Col
              xl={8}
              lg={8}
              md={12}
              sm={12}
              xs={12}
              className="py-5 text-right"
            >
              <ZapisConsult />
            </Col>
            <Col
              xl={4}
              lg={4}
              md={12}
              sm={12}
              xs={12}
              className="bg-primary p-5"
            >
              <ContactData />
            </Col>
          </Row>
        </Container>
      </div>
      <CheckText text={workExperience} />
    </Layout>
  )
}

export const query = graphql`
  {
    sanityHome {
      _rawPrinciplesBlock(resolveReferences: { maxDepth: 10 })
      _rawBody(resolveReferences: { maxDepth: 10 })
      _rawPricingBlock(resolveReferences: { maxDepth: 10 })
      workExperience
      header
      header2
      id
      title
      thumbnail {
        asset {
          gatsbyImageData
        }
        attribution
        caption
      }
    }
  }
`

export default IndexPage

import React from "react"
import { Link } from "gatsby"
import { Image } from "react-bootstrap"
import QuestionIco from "../images/question.svg"
import { Button } from "react-bootstrap"

const FaQ = () => {
  return (
    <>
      <div data-aos="fade-in">
        <Link to="/faq" className="nounderline">
          <Button variant="outline-secondary" block>
            <Image
              src={QuestionIco}
              alt="question mark"
              className="questionico"
            />
            Имеются вопросы?
          </Button>
        </Link>
      </div>
    </>
  )
}
export default FaQ
